import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { CallIntelligenceApi } from '@frontend/api-analytics';
import { CallIntelQueries, CallIntelTypes } from '@frontend/api-call-intel';
import { useTranslation } from '@frontend/i18n';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useScopedAppFlagStore, useScopedQuery } from '@frontend/scope';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Accordion, Button, Heading, PhoneOutgoingIcon, useAlert, useModalControl } from '@frontend/design-system';
import { ContactActionCard, NextPreviousButtons } from '..';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { callIntelligenceUtils, findCitationTime } from '../../utils';
import { CallRecordingSection } from './call-recording-section';
import { CallTakeawayFeedbackBanner } from './call-takeaway-feedback-banner';
import { CallTasks } from './call-tasks';
import { LOCAL_STORAGE_KEYS } from './constants';
import { CallIntelMockData } from './demo-data';
import { useCallTakeawayPanelShallowStore, useCallTakeawayPanelStore } from './hooks';
import UnsavedChangesModal from './modal/unsaved-changes-modal';
import { formatServiceQualityTime, ServiceQuality, serviceQualityMapping } from './service-quality';
import { CallAnalysis, CallDetails, CallSummary } from '.';

// Constants
const ACCORDION_ITEM_COUNT = 3;
const ACCORDION_ITEM_HEIGHT = ACCORDION_ITEM_COUNT * 60; // 40px for button, 20px for padding

// Utility Functions
const processCallMetadata = (callMetadata: CallIntelTypes.CallMetadata) => {
  const { transcript, tasks } = callMetadata;

  const tasksWithCitationTime = tasks.map((task) => {
    let parsedMetadata;
    let citation = '';

    if (task.metadata) {
      try {
        parsedMetadata = JSON.parse(task.metadata);
        citation = parsedMetadata.citation || '';
      } catch (error) {
        console.error('Error parsing metadata:', error);
      }
    }

    return {
      ...task,
      citation,
      citationTime: findCitationTime(transcript, citation),
    };
  });

  return {
    ...callMetadata,
    tasks: tasksWithCitationTime,
  };
};

const generateServiceQualityMetadata = (serviceQualityRes: CallIntelTypes.ServiceQualityResponse) => {
  const flagTimeMapping: CallIntelTypes.ServiceQualityFlagTimeMapping = {};

  Object.entries(serviceQualityMapping).forEach(([key, flagType]) => {
    const items = serviceQualityRes[key as keyof CallIntelTypes.ServiceQualityResponse];

    const isDisabled =
      (key === 'issues' && serviceQualityRes.isIssuesDisabled) ||
      (key === 'resolution' && serviceQualityRes.isResolutionDisabled);

    if (!isDisabled && Array.isArray(items)) {
      items.forEach((item) => {
        const formattedTime = formatServiceQualityTime(item.tsStart);
        flagTimeMapping[formattedTime] = { type: flagType };
      });
    }
  });

  return {
    flagData: serviceQualityRes,
    flagTimeMapping,
  };
};

type Props = {
  defaultAccordion: CallIntelTypes.CallTakeawayAccordion;
  panelStyles?: Interpolation<Theme>;
  onClose?: () => void;
};

type CallTakeawayPanelProps = {
  onClose?: () => void;
};

const CallTakeawayPanelContent = ({ defaultAccordion, panelStyles, onClose }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const { modalProps, triggerProps } = useModalControl();

  const { getFeatureFlagValue } = useScopedAppFlagStore();
  const isServiceQualityEnabled = getFeatureFlagValue(featureFlags.enableCallIntelServiceQuality);

  const {
    handleNextClick: handleNextCall,
    handlePreviousClick: handlePreviousCall,
    isDemoAccount,
    nextCall,
    previousCall,
    selectedCall: call,
    selectedCallMetaData,
    selectedDemoTasks,
    setSelectedCall,
    setSelectedCallMetaData,
    setUnsavedEditChangesConfig,
    unsavedEditChangesConfig,
  } = useCallTakeawayPanelShallowStore(
    'handleNextClick',
    'handlePreviousClick',
    'isDemoAccount',
    'nextCall',
    'previousCall',
    'selectedCall',
    'selectedCallMetaData',
    'selectedDemoTasks',
    'setSelectedCall',
    'setSelectedCallMetaData',
    'setUnsavedEditChangesConfig',
    'unsavedEditChangesConfig'
  );
  const { setPersonId } = useContactPanelStore();
  const { setShow } = useSlidePanelStore();
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');

  const [accordions, setAccordions] = useState<string | null>(defaultAccordion);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const [highlightedCitation, setHighlightedCitation] = useState<CallIntelTypes.HighlightedCitation>({
    text: '',
    time: '',
  });

  const accordionRef = useRef<HTMLDivElement>(null);

  const isFailedCall = callIntelligenceUtils.isFailedCall(call?.status);
  const isPoorAudio = callIntelligenceUtils.isPoorAudio(call);
  const isSkippedCall = callIntelligenceUtils.isSkippedCall(call?.status);
  const isCallInProcessing = callIntelligenceUtils.isCallInProcessing(call?.status);

  const taskPayload = useMemo(() => {
    if (call?.id && call?.locationId) {
      return {
        locations: [call.locationId],
        callIds: [call.id],
        includeMetaData: true,
      };
    }

    return {};
  }, [call?.id, call?.locationId]);

  const {
    data: callMetaDataRes,
    refetch: refetchCallMetadata,
    isLoading: isLoadingCallMetadata,
  } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`callMetadata-${call?.id}-${call?.locationId}`),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(CallIntelMockData.callMetadataResponse(lastUsedVertical))
        : !(isFailedCall || isSkippedCall) && !isCallInProcessing && call?.id && call?.locationId
        ? CallIntelligenceApi.getCallMetaData(call.id, call.locationId)
        : CallIntelligenceApi.noopMutationFn(null),
    onError: () => {
      alert.error(t('Failed to fetch call summary and transcript'));
    },
    refetchOnWindowFocus: false,
    select: (data: CallIntelTypes.CallMetadataResponse) => {
      const { callMetadata, ...rest } = data ?? {};
      return { ...rest, callParticipantsMetadata: callMetadata };
    },
    staleTime: 1000 * 60 * 5,
  });

  const { data: taskDataRes, isFetching: isTaskFetching } = CallIntelQueries.useListTasks({
    demoData:
      selectedDemoTasks ??
      (call && CallIntelMockData.callTaskListResponse(call.taskCount, call.taskTypes, call.id, lastUsedVertical)),
    isDemoAccount: !!isDemoAccount,
    payload: taskPayload,
    realQueryOptions: {
      cacheTime: 0,
      staleTime: 0,
      enabled: !!(!isDemoAccount && call?.id && call?.locationId),
      onError: () => {
        alert.error(t('Failed to fetch call tasks'));
      },
    },
    demoQueryOptions: {
      enabled: !!(isDemoAccount && call?.id),
    },
  });

  const {
    data: serviceQualityRes,
    isLoading: isServiceQualityLoading,
    refetch: refetchServiceQuality,
  } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`call-service-quality-${call?.id}`),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(
            CallIntelMockData.callServiceQualityResponse(call?.serviceQualityFlags ?? [], lastUsedVertical)
          )
        : call
        ? CallIntelligenceApi.getServiceQuality(call.id, call.locationId)
        : Promise.resolve(undefined),
    onError: () => {
      alert.error(t('Failed to fetch service quality'));
    },
    refetchOnWindowFocus: false,
  });

  const handleBackLinkClick = useCallback(() => {
    setPersonId('', false);
    setShow(true, 'callTakeaway', { call });
  }, [call, setPersonId, setShow]);

  const handleAccordionChange = useCallback((value: string | null) => {
    setAccordions(value);
    setHighlightedCitation({ text: '', time: '' });
  }, []);

  const handleSelectingCitationTime = useCallback((citationtext: string, citationTime: string) => {
    setAccordions('call-summary');
    setHighlightedCitation({ text: citationtext, time: citationTime });
  }, []);

  const handleNextBtnClick = () => {
    const { hasUnsavedChanges, dontAskAgain } = unsavedEditChangesConfig;
    if (!dontAskAgain && hasUnsavedChanges) {
      setUnsavedEditChangesConfig({ hasUnsavedChanges: true, actionType: 'SHOW_MODAL' });
      return;
    }
    handleNextCall();
  };

  const handlePreviousBtnClick = () => {
    const { hasUnsavedChanges, dontAskAgain } = unsavedEditChangesConfig;
    if (!dontAskAgain && hasUnsavedChanges) {
      setUnsavedEditChangesConfig({ hasUnsavedChanges: true, actionType: 'SHOW_MODAL' });
      return;
    }

    handlePreviousCall();
  };

  const handletakeAwayClose = () => {
    const { hasUnsavedChanges, dontAskAgain } = unsavedEditChangesConfig;
    if (!dontAskAgain && hasUnsavedChanges) {
      setUnsavedEditChangesConfig({ hasUnsavedChanges: true, actionType: 'SHOW_MODAL' });
      return;
    }
    setSelectedCall(null);
    setSelectedCallMetaData(null);
    !!onClose && onClose();
  };

  useEffect(() => {
    if (callMetaDataRes && taskDataRes && serviceQualityRes && !isTaskFetching && !isServiceQualityLoading) {
      const serviceQualityMetadata = (
        isServiceQualityEnabled
          ? generateServiceQualityMetadata(serviceQualityRes)
          : {
              flagData: {
                issues: [],
                resolution: [],
              },
              flagTimeMapping: {},
            }
      ) as CallIntelTypes.ServiceQualityMetadata;

      const callMetadata = {
        ...callMetaDataRes,
        ...taskDataRes,
        serviceQualityMetadata,
      };

      setSelectedCallMetaData(processCallMetadata(callMetadata));
    }
  }, [
    callMetaDataRes,
    isServiceQualityEnabled,
    isServiceQualityLoading,
    isTaskFetching,
    serviceQualityRes,
    taskDataRes,
  ]);

  useEffect(() => {
    if (defaultAccordion) {
      handleAccordionChange(defaultAccordion);
    }
  }, [defaultAccordion]);

  useEffect(() => {
    if (call) {
      //Reset unsaved changes whenever its a new call, Its also called whenever i do edit in my state as edit changes the call-info in table
      setUnsavedEditChangesConfig({ hasUnsavedChanges: false, actionType: 'NO_ACTION' });
    }
  }, [call]);

  useEffect(() => {
    const storedConfig = localStorage.getItem(LOCAL_STORAGE_KEYS.CALL_INTEL_UNSAVED_CHANGES_MODAL_PREF);
    const dontAskAgain = storedConfig ? JSON.parse(storedConfig) : false;

    setUnsavedEditChangesConfig({
      ...unsavedEditChangesConfig,
      dontAskAgain,
    });
  }, []);

  useEffect(() => {
    const { actionType, dontAskAgain, hasUnsavedChanges } = unsavedEditChangesConfig;

    if (!dontAskAgain && actionType === 'SHOW_MODAL' && hasUnsavedChanges) {
      triggerProps.onClick();
    }
  }, [unsavedEditChangesConfig]);

  useLayoutEffect(() => {
    if (accordionRef?.current?.clientHeight) {
      setMaxHeight(accordionRef.current.clientHeight - ACCORDION_ITEM_HEIGHT);
    } else {
      setMaxHeight(undefined);
    }
  }, [call]);

  return (
    <div css={[styles.wrapper, panelStyles]}>
      <Heading className='heading' level={2}>
        {t('Call Takeaways')}
        <Button iconName='x' label={t('Close')} onClick={handletakeAwayClose} size='large' variant='secondary' />
      </Heading>
      <ContactActionCard
        backLinkAction={handleBackLinkClick}
        firstName={call?.person?.firstName}
        lastName={call?.person?.lastName}
        openProfileOnClick={!isDemoAccount}
        phoneNumber={callIntelligenceUtils.getPhoneNumber(call?.phoneNumber) ?? '-'}
        rightElement={
          call?.direction === CallIntelTypes.CallDirectionEnum.DIRECTION_OUTBOUND ? (
            <span style={{ color: theme.colors.neutral70 }}>
              <PhoneOutgoingIcon size={16} />
            </span>
          ) : null
        }
        showContactActions
        showOnlyName={!call?.person?.firstName && !call?.person?.lastName} // name field will show number
        trackingIdBase='call-takeaways-contact-action'
        userId={call?.person?.id}
        withBorder
      />

      {call && <CallRecordingSection call={call} isDemoAccount={isDemoAccount} />}

      <Accordion
        controlledValue={accordions}
        css={styles.accordion}
        onChange={handleAccordionChange}
        ref={accordionRef}
      >
        <Accordion.Item trackingId={trackingIds.callIntel.callSummaryAccordion} value='call-summary'>
          {call && (
            <CallSummary
              call={call}
              callMetadata={selectedCallMetaData}
              highlightedCitation={highlightedCitation}
              isDemoAccount={isDemoAccount}
              isFailedCall={isFailedCall}
              isLoading={isLoadingCallMetadata || isTaskFetching}
              isPoorAudio={isPoorAudio}
              isSkippedCall={isSkippedCall}
              maxHeight={maxHeight}
            />
          )}
        </Accordion.Item>
        <Accordion.Item trackingId={trackingIds.callIntel.callTaskAccordion} value='call-tasks'>
          {call && (
            <CallTasks
              call={call}
              callMetadata={selectedCallMetaData}
              callStatus={{ isFailedCall, isPoorAudio, isSkippedCall }}
              isDemoAccount={isDemoAccount}
              isLoading={isLoadingCallMetadata || isTaskFetching}
              onSelectingCitationTime={handleSelectingCitationTime}
            />
          )}
        </Accordion.Item>

        {isServiceQualityEnabled && (
          <Accordion.Item trackingId={trackingIds.callIntel.serviceQualityAccordion} value='service-quality'>
            {call && (
              <ServiceQuality
                call={call}
                callStatus={{ isFailedCall, isPoorAudio, isSkippedCall }}
                isDemoAccount={isDemoAccount}
                isLoading={isLoadingCallMetadata || isServiceQualityLoading}
                onSelectingCitationTime={handleSelectingCitationTime}
                refetchServiceQuality={refetchServiceQuality}
                serviceQuality={serviceQualityRes}
              />
            )}
          </Accordion.Item>
        )}

        <Accordion.Item trackingId={trackingIds.callIntel.callAnalysisAccordion} value='call-analysis'>
          {call && (
            <CallAnalysis
              call={call}
              isFailedCall={isFailedCall}
              isPoorAudio={isPoorAudio}
              isSkippedCall={isSkippedCall}
            />
          )}
        </Accordion.Item>
        <Accordion.Item trackingId={trackingIds.callIntel.callDetailsAccordion} value='call-details'>
          {call && <CallDetails call={call} isDemoAccount={isDemoAccount} />}
        </Accordion.Item>
      </Accordion>
      <div css={styles.feedbackFooter}>
        {!isFailedCall && !isSkippedCall && selectedCallMetaData && (
          <CallTakeawayFeedbackBanner
            call={call}
            callMetadata={selectedCallMetaData}
            refetchCallMetadata={refetchCallMetadata}
            isDemoAccount={isDemoAccount}
          />
        )}
        <NextPreviousButtons
          disableNext={!nextCall}
          disablePrevious={!previousCall}
          onNextClick={handleNextBtnClick}
          onPreviousClick={handlePreviousBtnClick}
          trackingIdBase='ci-call-takeaway-panel'
        />
      </div>

      {unsavedEditChangesConfig.hasUnsavedChanges && unsavedEditChangesConfig.actionType === 'SHOW_MODAL' && (
        <UnsavedChangesModal
          modalProps={modalProps}
          onClose={() => {
            setUnsavedEditChangesConfig({ actionType: 'CLOSE', dontAskAgain: false, hasUnsavedChanges: true });
            localStorage.setItem(LOCAL_STORAGE_KEYS.CALL_INTEL_UNSAVED_CHANGES_MODAL_PREF, String(false));
          }}
          onDiscard={() => {
            setUnsavedEditChangesConfig({ actionType: 'DISCARD', hasUnsavedChanges: true });
          }}
          onSave={() => {
            setUnsavedEditChangesConfig({ actionType: 'SAVE', hasUnsavedChanges: true });
          }}
        />
      )}
    </div>
  );
};

export const CallTakeawayPanelTray = () => {
  const { defaultAccordion, selectedCall } = useCallTakeawayPanelShallowStore('defaultAccordion', 'selectedCall');

  return selectedCall ? (
    <CallTakeawayPanelContent
      defaultAccordion={defaultAccordion}
      key={selectedCall.id}
      panelStyles={{ padding: theme.spacing(4), overflowY: 'auto' }}
    />
  ) : null;
};

export const CallTakeawayPanel = ({ onClose }: CallTakeawayPanelProps) => {
  const { selectedCall, defaultAccordion } = useCallTakeawayPanelShallowStore('selectedCall', 'defaultAccordion');

  // The key helps to rerender the component when the call changes to avoid stale content and state
  return selectedCall ? (
    <CallTakeawayPanelContent defaultAccordion={defaultAccordion} key={selectedCall.id} onClose={onClose} />
  ) : null;
};

CallTakeawayPanel.cleanup = () => {
  useCallTakeawayPanelStore.getState().reset();
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(4)};
    overflow: hidden auto;

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: ${theme.spacing(3)};
    }
  `,

  accordion: css`
    flex: 1 1 100%;

    > div {
      box-shadow: none;
      border: 1px solid ${theme.colors.neutral20};
      margin-bottom: ${theme.spacing(2)};
    }

    > div h3 button section div svg {
      fill: ${theme.colors.neutral70};
    }

    div[data-active='true'] h3 button {
      background-color: ${theme.colors.neutral5};
      border-bottom: 1px solid ${theme.colors.neutral10};

      section div svg {
        fill: ${theme.colors.neutral90};
      }
    }
  `,

  feedbackFooter: css`
    display: flex;
    justify-content: space-between;
    align-items:  'center;
    flex-direction: row};
  `,
};
