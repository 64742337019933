import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { AnalysisType, Rating } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ChecklistField,
  ContentLoader,
  Modal,
  ModalControlModalProps,
  Text,
  TextareaField,
  useControlledField,
  ChecklistMenuField,
  useFormField,
  Button,
  Image,
} from '@frontend/design-system';
import { FeedbackDemoDesignB } from '../assets/images';
import Badge from './call-intelligence/badge';
import { useOptionsProvider } from './call-intelligence/hooks/use-options-provider';
import FeedbackIssueInput from './feedback-issue-input';
import { RatingInputField } from './rating-input-field';

export type Feedback = {
  allowWeaveToContact: boolean;
  issues?: AnalysisType[];
  message?: string;
  rating: Rating;
};

type Props = {
  disableCloseOnOverlayClick?: boolean;
  isLoading?: boolean;
  modalProps: ModalControlModalProps;
  onSubmit: (feedback: Feedback) => void;
  productName: string;
  showAllowToContact?: boolean;
  subtitle?: string;
  title?: string;
  trackingIdBase?: string;
  isAIFeedback?: boolean;
  isAIPositiveFeedback?: boolean;
};

export const FeedbackModal = ({
  disableCloseOnOverlayClick,
  isLoading,
  modalProps,
  onSubmit,
  productName,
  showAllowToContact = true,
  isAIFeedback,
  isAIPositiveFeedback,
  subtitle,
  title,
  trackingIdBase = 'feedback-modal',
}: Props) => {
  const { t } = useTranslation('analytics');

  const [allowWeaveToContact, setAllowWeaveToContact] = useState<[string]>(['yes']);
  const [message, setMessage] = useState<string>();
  const [rating, setRating] = useState<Rating | undefined>();
  const [isDemoGIFOpen, setIsDemoGIFOpen] = useState(false);

  const { getOptions } = useOptionsProvider();

  const feedbackOptions = getOptions('feedbackIssueTypes', 'array');

  const nodeId = useFloatingNodeId();
  const { refs, context, floatingStyles } = useFloating<HTMLDivElement>({
    nodeId: nodeId,
    whileElementsMounted: autoUpdate,
    open: isDemoGIFOpen,
    onOpenChange: setIsDemoGIFOpen,
    middleware: [offset(0), flip(), shift({ padding: 60 })],
    placement: 'right',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, {
      outsidePress: (event) => !(event?.target as HTMLElement)?.closest('svg'),
    }),
  ]);

  const feedbackChecklistProps = useFormField({
    type: 'checklist',
  });

  const messageField = useControlledField({
    onChange: setMessage,
    type: 'text',
    value: message,
  });

  const allowWeaveContactField = useControlledField({
    onChange: setAllowWeaveToContact,
    type: 'checklist',
    value: allowWeaveToContact,
  });

  const handleSubmit = () => {
    onSubmit({
      allowWeaveToContact: allowWeaveToContact.includes('yes'),
      message,
      rating: rating!,
      issues: feedbackChecklistProps.value as AnalysisType[],
    });
  };

  const handleRemoveIssueLabel = (value: string) => {
    const updatedValues = feedbackChecklistProps.value.filter((item) => item === value);

    // Use the same name as in your ChecklistMenuField
    feedbackChecklistProps.onChange({
      name: 'issues-selector',
      value: updatedValues,
    });
  };

  useEffect(() => {
    if (!modalProps.show) {
      setAllowWeaveToContact(['yes']);
      setMessage(undefined);
      setRating(undefined);
      feedbackChecklistProps.onChange({ name: 'checklist', value: [] });
    }
  }, [modalProps.show]);

  return (
    <Modal
      {...modalProps}
      css={styles.modal}
      disableCloseOnEscape={isLoading}
      disableCloseOnOverlayClick={disableCloseOnOverlayClick || isLoading}
      maxWidth={600}
    >
      <Modal.Header onClose={modalProps.onClose}>
        {title || t('How would you rate your experience with {{productName}}?', { productName })}
      </Modal.Header>
      <Modal.Body>
        <Text>
          {subtitle ||
            t(
              "Please share as much detail about what's working well, issues you are experiencing, or suggestions for how we can improve the product."
            )}
        </Text>
        {!isAIFeedback && <RatingInputField onChange={setRating} trackingIdBase={trackingIdBase} value={rating} />}
        {isAIFeedback && !isAIPositiveFeedback && (
          <>
            <div css={bannerStyle}>
              <Badge label={t('New')} />
              <Text size='medium'>{t('AI outputs can be corrected under Call Analysis.')}</Text>
              <Button
                iconName='video'
                css={bannerShowIconStyle}
                variant='tertiary'
                ref={refs.setReference}
                onClick={() => setIsDemoGIFOpen(!isDemoGIFOpen)}
                {...getReferenceProps()}
              >
                {t('Show Me')}
              </Button>
            </div>
            <ChecklistMenuField
              css={styles.checklistWrapper}
              {...feedbackChecklistProps}
              customRender={({ selectedKeys }) => {
                const selectedLabels: string[] = selectedKeys
                  .map((key) => feedbackOptions.find((option) => option.id === key)?.label)
                  .filter((label): label is string => Boolean(label));

                return (
                  <FeedbackIssueInput
                    selectedLabels={selectedLabels}
                    selectedKeys={selectedKeys}
                    onRemoveLabel={handleRemoveIssueLabel}
                  />
                );
              }}
              label={t('Select Issues (Optional)')}
              name='issues-selector'
              showSearchBar={false}
              showSelectionBar={false}
              sortLabelsBySelection={false}
              placement='bottom-start'
            >
              {feedbackOptions.map((value) => (
                <ChecklistMenuField.Option key={value.id} value={value.id}>
                  {value.label}
                </ChecklistMenuField.Option>
              ))}
            </ChecklistMenuField>
          </>
        )}
        <TextareaField
          {...messageField}
          css={[
            showAllowToContact &&
              css`
                margin-bottom: ${theme.spacing(2)};
              `,
          ]}
          data-trackingid={`${trackingIdBase}-message`}
          label={t('Feedback (Optional)')}
          name='product-feedback'
        />
        {showAllowToContact && (
          <ChecklistField {...allowWeaveContactField} label='' name='allowContact'>
            <ChecklistField.Option name='yes' trackingId={`${trackingIdBase}-allow-weave-contact`}>
              {t('Allow Weave to contact me about my response')}
            </ChecklistField.Option>
          </ChecklistField>
        )}
      </Modal.Body>
      <Modal.Actions
        disablePrimary={isAIFeedback ? !(message || feedbackChecklistProps.value.length > 0) : !rating}
        onPrimaryClick={handleSubmit}
        onSecondaryClick={modalProps.onClose}
        primaryLabel={t('Submit')}
        primaryTrackingId={`submit-product-feedback-for-${productName.toLowerCase().replaceAll(' ', '-')}`}
        secondaryLabel={t('Cancel')}
        secondaryTrackingId={`cancel-product-feedback-for-${productName.toLowerCase().replaceAll(' ', '-')}`}
      />
      <ContentLoader show={isLoading} />
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={{ ...floatingStyles, zIndex: theme.zIndex.popover }}
            >
              {isDemoGIFOpen && <DemoImage />}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      </FloatingNode>
    </Modal>
  );
};

const DemoImage = () => {
  return (
    <div css={demoImageStyle.container}>
      <div css={demoImageStyle.imageBoarder}>
        <Image css={demoImageStyle.img} src={FeedbackDemoDesignB} />
      </div>
    </div>
  );
};

const demoImageStyle = {
  container: css`
    width: fit-content;
    background-color: ${theme.colors.white};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadows.floating};
  `,
  imageBoarder: css`
    width: fit-content;
    background-color: ${theme.colors.indigo5};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.small};
  `,
  img: css`
    width: 320px;
    border-radius: ${theme.borderRadius.small};
  `,
};

const bannerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.secondary.seaweed50};
  background-color: ${theme.colors.secondary.seaweed5};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};
`;

const bannerShowIconStyle = css`
  width: 130px;
  &:hover {
    background-color: unset;
  }
`;

const styles = {
  modal: css`
    width: 100%;
    @media screen and (min-width: 648px) {
      width: 100%;
    }
  `,
  chip: css`
    background-color: ${theme.colors.primary5};
    font-size: ${theme.fontSize(12)};
    border: none;
    color: ${theme.colors.neutral90};
    max-width: ${theme.spacing(28)};

    button {
      padding: ${theme.spacing(0.5)};
      svg {
        width: ${theme.spacing(1)};
        height: ${theme.spacing(1)};
        color: ${theme.colors.neutral90};
      }
    }
  `,
  checklistWrapper: css`
    div:last-child {
      gap: ${theme.spacing(0)} !important;
    }

    input {
      height: ${theme.spacing(3)};
    }
  `,
};
