import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';
import Badge from './badge';
import { useVisibilityTracker } from './hooks/use-visibility-tracker';

type CorrectionBannerProps = {
  canMakeCorrection?: boolean;
  isReviewed: boolean;
  onMakeCorrection?: () => void;
  onViewEditHistory?: () => void;
  showEditHistory?: boolean;
  trackingIds?: {
    correctionBtn?: string;
    viewHistory?: string;
  };
};

const CorrectionBanner: React.FC<CorrectionBannerProps> = ({
  canMakeCorrection = false,
  isReviewed = false,
  onMakeCorrection,
  onViewEditHistory,
  showEditHistory = false,
  trackingIds,
}) => {
  const { t } = useTranslation('analytics');

  const { isVisible, elementRef } = useVisibilityTracker({
    identifier: 'editAI',
    maxDays: 3,
    isNewFeature: true,
  });

  const isInteractive = canMakeCorrection || showEditHistory;

  return (
    <div css={wrapperStyles({ isInteractive })}>
      <div css={styles.betaBadgeWrapper}>
        {isVisible && (
          <div ref={elementRef}>
            <Badge label={t('New')} />
          </div>
        )}
        <Text size='small' css={styles.noWrap}>
          {isReviewed ? t('Accuracy reviewed.') : t('Is this analysis accurate?')}
        </Text>
      </div>

      <div css={styles.actions}>
        {canMakeCorrection && (
          <TextLink
            as='p'
            css={styles.actionBtn}
            size='small'
            weight='bold'
            onClick={onMakeCorrection}
            trackingId={trackingIds?.correctionBtn}
          >
            {t('Make a Correction')}
          </TextLink>
        )}
        {showEditHistory && canMakeCorrection && <div css={styles.divider} />}
        {showEditHistory && (
          <TextLink
            as='p'
            css={styles.actionBtn}
            size='small'
            weight='bold'
            onClick={onViewEditHistory}
            trackingId={trackingIds?.viewHistory}
          >
            {t('View Edit History')}
          </TextLink>
        )}
      </div>
    </div>
  );
};

const wrapperStyles = ({ isInteractive }: { isInteractive: boolean }) => css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.large};
  padding: ${theme.spacing(1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${theme.shadows.light};
  flex-wrap: wrap;

  width: ${isInteractive ? '100%' : 'max-content'};
  @media screen and (max-width: ${breakpoints.medium.min}px) {
    gap: ${isInteractive ? theme.spacing(0.5) : theme.spacing(0)};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.neutral20};
    box-shadow: ${theme.shadows.light};
    width: min-content;
  }
`;

const styles = {
  actionBtn: css`
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  `,
  actions: css`
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
  divider: css`
    border: 1px solid ${theme.colors.neutral20};
  `,
  noWrap: css`
    white-space: nowrap;
  `,
  betaBadgeWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(0.5)};
  `,
};

export default CorrectionBanner;
